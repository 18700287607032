<template>
  <div class="assignment-map">
    <gmap-map
      ref="map"
      :center="taiSengHub"
      :zoom="zoom"
      :options="mapOptions"
      @dragstart="onDragStart"
      @dragend="onDragEnd">
      <gmap-polygon
        v-for="(polygon, i) in polygons"
        :key="`gmap_polygon_${i}`"
        :paths="polygon.paths"
        :options="polygon.options"/>
      <template v-for="hub in hubs">
        <map-marker
          v-if="hub.latitude && hub.longitude"
          :key="`map_marker_hub_${hub.id}`"
          :position="{ lat: hub.latitude, lng: hub.longitude }"
          :type="MAP_MARKER_TYPES.HUB"
          :src="getIconSrc(MAP_MARKER_TYPES.HUB)"
          :label="hub.shortCode.split('-')[0]"
          :zIndex="0"/>
      </template>
      <template v-for="trip in trips">
        <map-marker
          v-if="trip.latitude && trip.longitude"
          :key="`map_marker_trip_${trip.id}`"
          :position="{ lat: trip.latitude, lng: trip.longitude }"
          :type="MAP_MARKER_TYPES.COURIER"
          :src="getIconSrc(MAP_MARKER_TYPES.COURIER)"
          :label="trip.fulfilmentUser ? trip.fulfilmentUser.name : trip.id"
          :zIndex="0"/>
      </template>
      <gmap-polyline
        v-if="tripPolyline.path"
        :path="tripPolyline.path"
        :options="tripPolyline.options"/>
      <map-marker
        v-for="(task, i) in allTasks"
        :key="`map_marker_task_${task.orderIdentifier}`"
        :position="{ lat: task.latitude, lng: task.longitude }"
        :type="getMarkerType(task)"
        :src="getIconSrc(getMarkerType(task), task.startTime)"
        :label="getTimeslotId(task)"
        :zIndex="getMarkerIndex(task, i)"
        @mouseover="onMouseOverTask(task)"
        @mouseout="onMouseOutTask(task)"
        @click="onClickTask(task)"/>
    </gmap-map>
  </div>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import theme from '@json/google-maps-theme.json'
import MapMarker from '@components/MapMarker'

import { MAP_MARKER_TYPES, getIconSrc } from '@js/map-marker-utils'
import { mapActions, mapGetters } from 'vuex'
import { MAP_MARKER_COLORS } from '@js/map-marker-colors'
import { TASK_STATES } from '@js/constants'
import { isTripDispatched } from '@js/trip-utils'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ''
  }
})

export default {
  name: 'assignment-map',
  components: {
    MapMarker
  },
  data() {
    return {
      MAP_MARKER_TYPES,
      taiSengHub: {
        lat: 1.335164,
        lng: 103.8849477
      },
      zoom: 12,
      mapOptions: {
        minZoom: 11,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: theme,
        backgroundColor: '#222'
      },
      directionsService: null,
      directionsRenderer: null,
      routeCoordinates: [],
      allTasks: []
    }
  },
  computed: {
    ...mapGetters('hub', [
      'hubs',
      'getHubByShortCode'
    ]),
    ...mapGetters('task', [
      'highlightedTask',
      'tasks'
    ]),
    ...mapGetters('timeslot', [
      'getTimeslotIdByStartTime'
    ]),
    ...mapGetters('trip', [
      'assigningTripTasks',
      'selectedAssigningTrip',
      'trips',
      'tripsTasks',
      'tripPolyline'
    ]),
    polygons() {
      return this.hubs.map(hub => {
        return {
          paths: hub.boundary,
          options: {
            fillOpacity: 0.1,
            strokeColor: this.getPolygonStrokeColor(hub.shortCode),
            strokeOpacity: 1.0,
            strokeWeight: 2
          }
        }
      })
    },
    combinedTasks() {
      return this.tasks.concat(this.tripsTasks)
    },
    remainingTripTasks() {
      if (this.selectedAssigningTrip) {
        return this.selectedAssigningTrip.tasks.filter(task => {
          return ![TASK_STATES.FAILED, TASK_STATES.COMPLETED].includes(task.state)
        })
      }
      return []
    },
    routePath() {
      return {
        path: this.routeCoordinates,
        options: {
          strokeColor: 'black',
          strokeOpacity: 0.8,
          strokeWeight: 4
        }
      }
    }
  },
  watch: {
    combinedTasks() {
      this.allTasks = this.combinedTasks
    }
  },
  methods: {
    ...mapActions('task', [
      'setHighlightedTask'
    ]),
    ...mapActions('trip', [
      'addTripTask',
      'removeTripTask'
    ]),
    onDragStart() {
      this.$emit('dragstart')
    },
    onDragEnd() {
      this.$emit('dragend')
    },
    getPolygonStrokeColor(hubShortCode) {
      const shortCode = hubShortCode.split('-')[0]
      switch (shortCode) {
        case 'TS':
          return MAP_MARKER_COLORS[1] // red
        case 'JB':
          return MAP_MARKER_COLORS[9] // green
        case 'ON':
          return MAP_MARKER_COLORS[13] // blue
        case 'FP':
          return MAP_MARKER_COLORS[17] // indigo
      }
      return 'black'
    },
    getMarkerType(task) {
      if (this.highlightedTask && this.highlightedTask.id === task.id) {
        return MAP_MARKER_TYPES.SELECTED
      } else if (task.tripId) {
        return MAP_MARKER_TYPES.ASSIGNED
      }
      return MAP_MARKER_TYPES.STANDARD
    },
    getMarkerIndex(task, index) {
      // show highlighted tasks at the top
      // show unassigned tasks above assigned ones
      if (this.highlightedTask && this.highlightedTask.id === task.id) {
        return this.allTasks.length * 2 + 1
      } else if (task.tripId) {
        return index + 1
      }
      return this.allTasks.length + index + 1
    },
    getIconSrc(type = MAP_MARKER_TYPES.STANDARD, startTime = null) {
      return getIconSrc(type, startTime)
    },
    getTimeslotId(task) {
      const id = this.getTimeslotIdByStartTime(task.startTime)
      if (id) {
        return id.toString()
      }
      return ''
    },
    onMouseOverTask(task) {
      this.setHighlightedTask(task)
    },
    onMouseOutTask(task) {
      if (this.highlightedTask && this.highlightedTask.id === task.id) {
        this.setHighlightedTask(null)
      }
    },
    onClickTask(task) {
      if (this.selectedAssigningTrip && isTripDispatched(this.selectedAssigningTrip)) {
        // if selected assigning trip is already dispatched
        return this.$buefy.dialog.alert({
          message: this.$t('assignment.rightPanel.assign.error.tripStarted')
        })
      }

      // check to show remove confirmation
      const assigningTripTask = this.assigningTripTasks.find(tripTask => tripTask.id === task.id)
      const assignedTask = this.tripsTasks.find(tripTask => tripTask.id === task.id)
      if (assigningTripTask && assignedTask) {
        this.$buefy.dialog.confirm({
          title: this.$t('assignment.rightPanel.assign.task.remove.title'),
          message: this.$t('assignment.rightPanel.assign.task.remove.message'),
          confirmText: this.$t('assignment.rightPanel.assign.task.remove.confirm'),
          cancelText: this.$t('assignment.rightPanel.assign.task.remove.cancel'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.removeTripTask(task)
          }
        })
      } else if (!assigningTripTask && !assignedTask) {
        this.addTripTask(task)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .vue-map-container {
    width: 100%;
    height: 100%;

    &::v-deep {
      @import '~@css/map-markers';
    }
  }
}
</style>
